import React, { useEffect, useState } from 'react';



const SkillButtons = ({ skills }) => {
    const colors = [
        '#8FBB76', // Custom Green
        '#FCC76A', // Custom Yellow
        '#F4A755', // Custom Orange
        '#E06C79', // Custom Pink
        '#8A5F9E', // Custom Purple
        '#519FB0', // Custom Blue
  ];

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  // Initialize skills and setSkills with the initial value
  const [shuffledSkills, setSkills] = useState([]);

  useEffect(() => {
    // Shuffle the initialSkills array and update the state
    const initialSkills = [...skills];
    shuffleArray(initialSkills);
    setSkills(initialSkills);
  }, [skills]);

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  return (
    <div className="button-container">
      {shuffledSkills.map((skill, index) => (
        <button
          key={index}
          className="button btn skills-btn"
          style={{ backgroundColor: getRandomColor() }}
        >
          {skill}
        </button>
      ))}
    </div>
  );
};

export default SkillButtons;
