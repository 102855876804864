import React, { Component } from "react";
import Slide from "react-reveal";
import SkillButtons from './SkillButtons';


class Resume extends Component {
  

  render() {
    if (!this.props.data) return null;

    
    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p><b>Relevant Coursework:</b><br></br>{education.relevant_coursework}</p>
          <p><b>Awards and Recognition:</b>
          <i>
          <li>{education.award_1}</li>
          <li>{education.award_2}</li>
          </i>
          </p>
        </div>
      );
    });

    const work = this.props.data.work.map(function (work) {
      return (
        <div key={work.company}>
          <h3>{work.company} <em>{work.company_sub_heading}</em></h3>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
         
       
            <p>{work.description[0]}</p>
            <p>{work.description[1]}</p>
            <p>{work.description[2]}</p>
            <p>{work.description[3]}</p>
            <p>{work.description[4]}</p>
        </div>
      );
    });

    

   

    const skills = [
      'Java', 'C++', 'C', 'C#', 'Python', 'HTML', 'CSS', 
      'JavaScript', 'TypeScript', 'Swift', 'Objective-C', 'Verilog', 
      'MIPS Assembly', 'R', 'Spring Boot', 'Django',
      'React', 'NodeJS', 'NextJS', 'jQuery', 'Bootstrap', 'Selenium', 
      'PyTorch', 'Git',
      'Unix/Linux', 'Docker', 'AWS', 'Jira', 'Maven', 
      'MySQL', 'PostgreSQL', 'MongoDB', 'Memcached',
      'Agile Development', 'Test-Driven Development', 
      'UI/UX', 'Workflow Automation', 'CI/CD', 
      'JSON', 'JUnit', 'NumPy', 'Pandas', 'PyCharm',
      'RESTful APIs', 'Tesseract', 'XML', 'OCR', 'Drools', 'Machine Learning', 'Scikit-Learn', 'Go-Lang' 
    ];



    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Education</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{education}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Work Experience</span>
              </h1>
            </div>

            <div className="nine columns main-col">{work}</div>
          </div>
        </Slide>


        <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills at a glance</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              
      <SkillButtons skills={skills} />

            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;
